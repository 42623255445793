import { StaticPage } from '../types/pages';

export interface NavigationItem {
  children?: Array<NavigationItem>;
  page: StaticPage;
  iconKey?: string;
}

export const MAIN_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    page: StaticPage.Laser,
    iconKey: 'gangstaLogo',
  },
  {
    page: StaticPage.Home,
  },
  {
    page: StaticPage.About,
  },
  {
    page: StaticPage.Trainings,
  },
  {
    page: StaticPage.Treatments,
  },
  {
    page: StaticPage.Prices,
  },
  {
    page: StaticPage.Gallery,
  },
  {
    page: StaticPage.Contact,
  },
  {
    page: StaticPage.Blog,
  },
];
