import React from 'react';
import Helmet from 'react-helmet';

import { usePageSeoData } from '../data';

export interface PageSeoProps {
  id: string;
}

const PageSeo: React.FC<PageSeoProps> = ({ id }) => {
  const { data } = usePageSeoData(id);

  return (
    <Helmet
      htmlAttributes={{ lang: 'pl-PL' }}
      title={data.title}
      meta={[
        {
          name: `description`,
          content: data.description,
        },
        {
          property: `og:title`,
          content: data.title,
        },
        {
          property: `og:description`,
          content: data.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: data.title,
        },
        {
          name: `twitter:description`,
          content: data.description,
        },
      ]}
    />
  );
};

export default PageSeo;
