import '../styles/aos.scss';

import { win } from './window';

let observer: IntersectionObserver | undefined;

const aos = () => {
  if (win?.IntersectionObserver === undefined) {
    document.body.classList.add('aos-visible');

    return;
  }

  const instance =
    observer ||
    new IntersectionObserver(entries =>
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('aos-visible');
          instance.unobserve(entry.target);
        }
      }),
    );

  Array.from(document.body.querySelectorAll('[data-aos]')).forEach(node =>
    instance.observe(node),
  );
};

export default () => setTimeout(aos, 100);
