import GatsbyImage from 'gatsby-image';
import { isNil } from 'lodash';
import React from 'react';

import { useImageData } from '../data/hooks';

interface ImageProps {
  id: string;
}

export const Image: React.FC<ImageProps> = ({ id }) => {
  const data = useImageData(id);

  return !isNil(data?.data?.bitmap?.fluid) ? (
    <GatsbyImage fluid={data?.data?.bitmap?.fluid} alt={data?.data?.alt} />
  ) : !isNil(data?.data?.vector?.url) ? (
    <img src={data?.data?.vector?.url} alt={data?.data?.alt} />
  ) : (
    <></>
  );
};

export default Image;
