import '../styles/social-media.scss';

import React from 'react';

import { useSocialMediaData } from '../data/hooks/use-social-media-data';
import Image from './image';

const SocialMedia: React.FC = () => {
  const { data } = useSocialMediaData().fallback;

  return (
    <div className="social-media">
      {data.items.map((item, index) => (
        <a
          key={index}
          href={item.data.href}
          target="_blank"
          aria-label={item.id}
          rel="noopener noreferrer"
        >
          <Image id={item.data.icon.id} />
        </a>
      ))}
    </div>
  );
};

export default SocialMedia;
