import '../styles/cookies-dialog.scss';

import { Button, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';

import { win } from '../fragments/window';
import { useLinkResolver, useStaticPageDataResolver } from '../hooks';
import { StaticPage } from '../types';
import { Col, Container, Row } from './layout';
import { makeLink } from './navigation';

const COOKIES_ID = 'cookies-beautyacademyoy.com';

const CookiesDialog: React.FC = () => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);

  const resolveLink = useLinkResolver();
  const staticPageResolver = useStaticPageDataResolver();

  useEffect(() => {
    const maybeCookies = win?.localStorage.getItem(COOKIES_ID);
    const cookies =
      maybeCookies === undefined || maybeCookies === null
        ? undefined
        : JSON.parse(maybeCookies);

    if (cookies !== true) {
      setDrawerOpened(true);
    }
  }, []);

  const handleCookiesAccept = () => {
    win?.localStorage.setItem(COOKIES_ID, JSON.stringify(true));
    setDrawerOpened(false);
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      mask={false}
      height={125}
      visible={isDrawerOpened}
      className="cookies-dialog-drawer"
    >
      <Container>
        <Row gutters="1rem">
          <Col span={{ sm: 24, md: 20 }}>
            <p>
              Nasza strona internetowa wykorzystuje pliki cookie, aby zapewnić
              jej prawidłowe działanie. Korzystanie z aplikacji bez zmiany
              ustawień dotyczących cookies oznacza, że będą one przechowywane w
              pamięci urządzenia. Ustawienia te można zmienić w przeglądarce
              internetowej.
              {makeLink(
                { page: StaticPage.PrivacyPolicy },
                staticPageResolver,
                resolveLink,
              )}
            </p>
          </Col>
          <Col span={{ sm: 24, md: 4 }}>
            <Button onClick={handleCookiesAccept} type="primary" block>
              Akceptuję
            </Button>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

export default CookiesDialog;
