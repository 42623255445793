/* eslint-disable simple-import-sort/sort */
import '../styles/navigation.scss';

import { useLocation, useNavigate } from '@reach/router';
import { Button, Drawer, Menu } from 'antd';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Img from 'gatsby-image';

import { MAIN_NAVIGATION_ITEMS, NavigationItem, THEME_CONFIG } from '../config';
import { ServiceId, ServiceType, useConfigData, usePageData } from '../data';
import {
  useLinkResolver,
  useServicePagesPartial,
  useStaticPageDataResolver,
} from '../hooks';
import SvgClose from '../images/close.svg';
import SvgHamburgerMenu from '../images/hamburger-menu.svg';
import { StaticPage } from '../types';
import { makeThemeClassName } from '../utils';
import Dropdown from './dropdown';
import Image from './image';
import { Box, Col, Container, Row } from './layout';
import SocialMedia from './social-media';

const OMIT_NAVIGATION_BAR_ITEMS = [
  StaticPage.Home,
  StaticPage.Contact,
  StaticPage.Handbook,
  StaticPage.PrivacyPolicy,
];

const DEFAULT_NAVIGATION_THEME_CLASSNAME = 'theme-secondary';

// TODO move to contentful
const NAVIGATION_CHILDREN_TITLES = {
  [ServiceId.Beginner]: 'Szkolenia dla początkujących',
  [ServiceId.Advanced]: 'Szkolenia dla zaawansowanych',
  [ServiceId.Other]: 'Szkolenia dla każdego',
};

const NAVIGATION_SERVICES_CHILDREN_ORDER = {
  [ServiceType.Trainings]: [
    ServiceId.Beginner,
    ServiceId.Advanced,
    ServiceId.Other,
  ],
  [ServiceType.Treatments]: [
    ServiceId.PmuBrows,
    ServiceId.PmuEyes,
    ServiceId.PmuLips,
    ServiceId.BioTatoo,
    ServiceId.PmuModifications,
    ServiceId.Laser,
    ServiceId.Other,
    ServiceId.Lashes,
  ],
};

export const makeLink = (
  item: NavigationItem,
  staticPageResolver: ReturnType<typeof useStaticPageDataResolver>,
  linkResolver: ReturnType<typeof useLinkResolver>,
  navigationLinkIcons: Record<string, any>,
): React.ReactNode => {
  const { id, data } = staticPageResolver(item.page);

  const navigationIcon =
    item.iconKey && navigationLinkIcons?.[item.iconKey]?.childImageSharp?.fluid;

  return (
    <Link
      activeClassName="active"
      className="link item"
      aria-label={data.navigationTitle}
      partiallyActive
      key={id}
      to={linkResolver(item.page)}
    >
      {navigationIcon ? <Img fluid={navigationIcon} /> : data.navigationTitle}
    </Link>
  );
};

interface NavigationProps {
  pageId: string;
}

const Navigation: React.FC<NavigationProps> = ({ pageId }) => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname: locationPathname } = useLocation();
  const resolveLink = useLinkResolver();
  const staticPageResolver = useStaticPageDataResolver();
  const configData = useConfigData();
  const pageData = usePageData(pageId);
  const servicePagesPartial = useServicePagesPartial();

  const navigationLinkIcons = useStaticQuery(graphql`
    query {
      gangstaLogo: file(relativePath: { eq: "gangsta-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navigationLinks = MAIN_NAVIGATION_ITEMS.filter(
    item => !OMIT_NAVIGATION_BAR_ITEMS.includes(item.page),
  );

  const companyLogo = (
    <Image
      id={
        [
          pageData?.data?.navigationTheme?.data?.mode,
          DEFAULT_NAVIGATION_THEME_CLASSNAME,
        ].some(value => value === 'theme-secondary')
          ? configData.fallback.data.logoLight.id
          : configData.fallback.data.logoDark.id
      }
    />
  );

  return (
    <header
      className={makeThemeClassName(pageData?.data?.navigationTheme, {
        'theme-secondary': true,
      })}
    >
      <Container>
        <Row
          align="middle"
          gutters={['2rem', '0rem']}
          style={{ height: '100%' }}
        >
          <Col span={{ sm: 12, md: 6, lg: 3 }}>
            <Link
              style={{ display: 'block', lineHeight: '0' }}
              to="/"
              aria-label="company logo"
            >
              {companyLogo}
            </Link>
          </Col>

          <Col span={{ lg: 16, xxl: 17 }} hidden={{ sm: true, lg: false }}>
            <Box
              className="navigation-desktop"
              style={{
                sm: { padding: '0 1rem' },
                xxl: { padding: '0 3rem' },
              }}
            >
              {navigationLinks.map(item => {
                const { id } = staticPageResolver(item.page);

                return servicePagesPartial[item.page] === undefined ? (
                  makeLink(
                    item,
                    staticPageResolver,
                    resolveLink,
                    navigationLinkIcons,
                  )
                ) : (
                  <Dropdown
                    key={id}
                    defaultValue={makeLink(
                      item,
                      staticPageResolver,
                      resolveLink,
                      navigationLinkIcons,
                    )}
                    component={NAVIGATION_SERVICES_CHILDREN_ORDER[
                      item.page
                    ].map(
                      serviceId =>
                        servicePagesPartial[item.page][serviceId] && (
                          <Menu.ItemGroup
                            title={NAVIGATION_CHILDREN_TITLES[serviceId]}
                            key={serviceId}
                          >
                            {servicePagesPartial[item.page][serviceId]?.map(
                              item => (
                                <Menu.Item
                                  style={
                                    item.path === locationPathname
                                      ? {
                                          background:
                                            THEME_CONFIG['theme-primary'],
                                          color:
                                            THEME_CONFIG['theme-on-primary'],
                                          borderRadius: '0.25rem',
                                        }
                                      : undefined
                                  }
                                  key={item.path}
                                  onClick={() => navigate(item.path)}
                                >
                                  {item.navigationTitle}
                                </Menu.Item>
                              ),
                            )}
                          </Menu.ItemGroup>
                        ),
                    )}
                  />
                );
              })}
            </Box>
          </Col>

          <Col
            span={{ sm: 12, lg: 5, xxl: 4 }}
            hidden={{ sm: true, md: false }}
          >
            <Box justify="center">
              <Button
                type="primary"
                onClick={() => navigate(resolveLink(StaticPage.Contact))}
              >
                Skontaktuj się
              </Button>
            </Box>
          </Col>

          <Col hidden={{ sm: false, lg: true }} span={{ sm: 12, md: 6 }}>
            <Box align="middle" fullHeight justify="end">
              <div
                className="hamburger-btn btn"
                tab-index="0"
                onClick={() => setDrawerOpened(true)}
              >
                <SvgHamburgerMenu />
              </div>
            </Box>
          </Col>
        </Row>
      </Container>

      <Box className="navigation-mobile" hidden={{ sm: false, lg: true }}>
        <Drawer
          placement="right"
          closable={false}
          width="100%"
          visible={isDrawerOpened}
        >
          <div className="drawer-head">
            <div className="drawer-head__company-logo">
              <Link to="/" aria-label="company logo">
                <Image id={configData.fallback.data.logoDark.id} />
              </Link>
            </div>

            <div
              className="close-btn btn"
              tab-index="0"
              onClick={() => setDrawerOpened(false)}
            >
              <SvgClose />
            </div>
          </div>

          <div className="drawer-body">
            {navigationLinks.map(link =>
              makeLink(
                link,
                staticPageResolver,
                resolveLink,
                navigationLinkIcons,
              ),
            )}
            <SocialMedia />
          </div>
        </Drawer>
      </Box>
    </header>
  );
};

export default Navigation;
