/* eslint-disable simple-import-sort/sort */
import '../styles/footer.scss';

import { Button, Typography } from 'antd';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MAIN_NAVIGATION_ITEMS } from '../config';
import { useConfigData } from '../data';
import { useLinkResolver } from '../hooks/use-link-resolver';
import { useStaticPageDataResolver } from '../hooks/use-static-page-data-resolver';
import { StaticPage } from '../types';
import { Box, Col, Container, Row, Section } from './layout';
import { makeLink } from './navigation';
import SocialMedia from './social-media';

const Footer: React.FC = () => {
  const { data: config } = useConfigData().fallback;
  const resolveLink = useLinkResolver();
  const staticPageResolver = useStaticPageDataResolver();

  const navigationLinkIcons = useStaticQuery(graphql`
    query {
      gangstaLogo: file(relativePath: { eq: "gangsta-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <Section className="theme-offwhite">
        <Container className="theme-offwhite">
          <Row className="footer__top">
            <Col span={{ sm: 24, md: 8 }}>
              <Typography.Title level={4}>Adres</Typography.Title>
              <Typography.Paragraph>
                {config.formattedAddress}
              </Typography.Paragraph>
            </Col>

            <Col span={{ sm: 24, md: 8 }}>
              <Typography.Title level={4}>Kontakt</Typography.Title>
              <div>
                <Button
                  type="link"
                  href={`tel:${config.phoneNumber}`}
                  size="large"
                >
                  {config.formattedPhoneNumber}
                </Button>
              </div>
              <div>
                <Button
                  type="link"
                  href={`mailto:${config.email}`}
                  size="large"
                >
                  {config.email}
                </Button>
              </div>
            </Col>

            <Col hidden={{ sm: true, md: false }} span={{ sm: 24, md: 8 }}>
              <Box className="footer__top-box theme-secondary">
                <Typography.Title
                  level={4}
                  style={{ margin: 0, color: '#cbcfd4' }}
                >
                  Napisz do nas
                </Typography.Title>
                <div>
                  <Button
                    type="link"
                    href={`mailto:${config.email}`}
                    size="large"
                    block
                  >
                    {config.email}
                  </Button>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row justify="center" className="footer__bottom" gutters="1rem">
            <Col span={{ sm: 24, md: 4 }}>
              <SocialMedia />
            </Col>

            <Col span={{ sm: 24, md: 15 }} hidden={{ sm: true, md: false }}>
              <Box justify="space-between" align="middle">
                {MAIN_NAVIGATION_ITEMS.map(item =>
                  makeLink(
                    item,
                    staticPageResolver,
                    resolveLink,
                    navigationLinkIcons,
                  ),
                )}
              </Box>
            </Col>

            <Col span={{ sm: 24, md: 5 }}>
              <Box justify="end" align="middle">
                {makeLink(
                  { page: StaticPage.Handbook },
                  staticPageResolver,
                  resolveLink,
                  navigationLinkIcons,
                )}
                {makeLink(
                  { page: StaticPage.PrivacyPolicy },
                  staticPageResolver,
                  resolveLink,
                  navigationLinkIcons,
                )}
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>

      <Container>
        <Box justify="center" align="middle">
          <Typography.Paragraph>
            {new Date().getFullYear()} Olena Yantsova Akademia Urody. Wszelkie
            prawa zastrzezone.
          </Typography.Paragraph>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
