import '../styles/boilerplate.scss';

import { BackTop } from 'antd';
import React, { useEffect } from 'react';

import aos from '../fragments/aos';
import CookiesDialog from './cookies-dialog';
import Footer from './footer';
import Navigation from './navigation';
import PageSeo from './page-seo';

interface BoilerplateProps {
  pageId: string;
  seoId: string;
}

const Boilerplate: React.FC<BoilerplateProps> = ({
  children,
  seoId,
  pageId,
}) => {
  useEffect(() => {
    aos();
  }, []);

  return (
    <>
      <PageSeo id={seoId} />
      <Navigation pageId={pageId} />
      <main id={pageId}>{children}</main>
      <CookiesDialog />
      <BackTop />
      <Footer />
    </>
  );
};

export default Boilerplate;
