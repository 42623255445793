import '../styles/dropdown.scss';

import { Dropdown as AntDropdown, Menu } from 'antd';
import { DropDownProps as AntDropDownProps } from 'antd/lib/dropdown';
import React from 'react';

import SvgArrowDown from '../images/arrow-down.svg';

interface DropDownProps
  extends Pick<AntDropDownProps, 'placement' | 'trigger'> {
  defaultValue: string | React.ReactNode;
  menuItems?: (string | React.ReactNode)[];
  component?: React.ReactNode;
}

const Dropdown: React.FC<DropDownProps> = ({
  defaultValue,
  menuItems,
  placement,
  trigger,
  component,
}) => (
  <AntDropdown
    trigger={trigger ?? ['hover', 'click']}
    placement={placement ?? 'bottomCenter'}
    overlay={
      <Menu className="dropdown-menu">
        {component
          ? component
          : menuItems.map((item, index) => (
              <Menu.Item key={index}>{item}</Menu.Item>
            ))}
      </Menu>
    }
  >
    <div className="dropdown-default-item">
      {defaultValue ?? ''}
      <SvgArrowDown />
    </div>
  </AntDropdown>
);

export default Dropdown;
