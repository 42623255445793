export const THEME_CONFIG = {
  'theme-primary': '#BC2626',
  'theme-on-primary': '#ffffff',
  'theme-secondary': '#13191f',
  'theme-on-secondary': '#ffffff',
  'theme-background-0dp': '#ffffff',
  'theme-on-background-0dp': '#22262b',
  'theme-background-1dp': '#f6f6f6',
  'theme-on-background-1dp': '#22262b',
  'theme-background-2dp': '#e9e9ea',
  'theme-on-background-2dp': '#302f39',
  'theme-background-3dp': '#22262b',
  'theme-on-background-3dp': '#f6f6f6',
  'theme-on-button-select': '#00376d',
  'theme-offwhite': '#ededed',
  'theme-home-slider-overlay-dark': '#494949',
  'theme-home-slider-overlay-light': '#7a7a7a',
  'primary-box-shadow': '-0.125rem 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.03)',
};
