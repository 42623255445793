/* eslint-disable simple-import-sort/sort */
import '../../styles/section-heading.scss';

import { Typography } from 'antd';
import React from 'react';

import { classNameMap } from '../../utils';
import { contentfulJSONToReactComponents } from '../../utils/contentful';
import { Box } from '../layout';
import { TitleProps } from 'antd/lib/typography/Title';

interface SectionHeadingProps {
  title?: string;
  titleLevel?: TitleProps['level'];
  description?: { json: any } | string;
  align?: 'start' | 'center' | 'end';
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  titleLevel = 3,
  align = 'center',
}) => (
  <Box
    justify={align}
    direction="column"
    className={classNameMap({
      'section-heading': true,
      [`section-heading--${align}`]: true,
    })}
  >
    {title && (
      <Typography.Title level={titleLevel} className="section-heading_title">
        {title}
      </Typography.Title>
    )}

    {typeof description === 'string' && (
      <Typography.Paragraph>{description}</Typography.Paragraph>
    )}

    {(description as any)?.json &&
      contentfulJSONToReactComponents((description as any).json)}
  </Box>
);

export default SectionHeading;
